import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Box, Divider, Stack, TextInput, Title } from '@mantine/core'
import Image from 'next/image'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import classes from '../SignInPage.module.css'

interface ISignInFormProps {
  onClickWalletSignIn: any
  onClickPasswordlessEmailSignIn: any
  onClickGoogleSignIn: any
  loading: boolean
  onClickXeroSignIn: () => void
  authType: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().required('E-mail is required').email('Please enter a valid email')
})

const SignInForm: FC<ISignInFormProps> = ({
  onClickWalletSignIn,
  onClickPasswordlessEmailSignIn,
  onClickGoogleSignIn,
  onClickXeroSignIn,
  loading,
  authType
}) => {
  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(validationSchema)
  })

  const handleOnChangeEmail = (e: any) => {
    setValue('email', e.target.value)
    trigger('email')
  }

  return (
    <Box className={classes.signInCard}>
      <Title order={2} visibleFrom="lg" className="text-center ">
        Sign in to your account
      </Title>
      <Title hiddenFrom="lg" order={1} className="text-left">
        Sign in to your account
      </Title>
      <form className="mt-4" onSubmit={handleSubmit(onClickPasswordlessEmailSignIn)}>
        <TextInput
          classNames={{ label: 'font-semibold' }}
          onChange={handleOnChangeEmail}
          size="md"
          label="Email"
          placeholder="johndoe@company.com"
          error={errors?.email?.message}
        />
        <Button size="md" disabled={loading} fullWidth className="mt-8" type="submit">
          Continue
        </Button>
      </form>

      <Divider className="my-8" label="OR" labelPosition="center" />
      <Stack align="stretch" justify="center">
        <Button
          leftSection={<Image src="/svg/Google.svg" height={24} width={24} alt="Google" />}
          disabled={loading}
          loading={authType === 'email' && loading}
          size="md"
          fw={500}
          variant="light"
          onClick={onClickGoogleSignIn}
        >
          Continue with Google
        </Button>
        <Button
          leftSection={<Image src="/svg/icons/wallet-icon.svg" height={24} width={24} alt="Google" />}
          disabled={loading}
          loading={authType === 'wallet' && loading}
          size="md"
          fw={500}
          variant="light"
          onClick={onClickWalletSignIn}
        >
          Continue with Wallet
        </Button>
        <Button
          leftSection={<Image src="/svg/icons/xero-logo-icon.svg" height={24} width={24} alt="Xero" />}
          disabled={loading}
          loading={authType === 'xero' && loading}
          size="md"
          fw={500}
          variant="light"
          onClick={onClickXeroSignIn}
        >
          Continue with Xero
        </Button>
      </Stack>
    </Box>
  )
}

export default SignInForm
